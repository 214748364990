import {useEffect, useRef, useState} from "react";

export function CheckInput({checked, oncheng, Name, className = '',labelClassName='',id, title, ...props}) {
    let lang = localStorage.getItem("i18nextLng");
    const languageDir = "language-" + lang;
    const get = (e) => {
        if (oncheng)
            oncheng(e.target);
    }
    return (
        <div className={"input " + languageDir}>
            <div>
                <label className={`flex gap-1 items-center mb-2 ${labelClassName} `}>
                    <input onChange={(e) => get(e)} name={Name} id={id} checked={checked} className={`${className}`} type="checkbox"/>
                    {props.children}
                </label>
            </div>
        </div>
    );
}
