import { Fragment, useEffect, useRef, useState } from "react";
import { Part } from "./components/part";
import PageHeading from "../../components/__simple/_pageheading";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import { PartRating } from "./components/rate";
import config from '../../configuration/config.json'
import { DropDownInput } from "../../components/_input/_dropdown";
import { LikeButton } from "../../components/__simple/_likeButton";
import { RatingSection } from "./components/ratingSection";
import { CarSection } from "./components/carSection";
import Breadcrumb from "../../components/__simple/breadcrumb";
import { useDispatch } from "react-redux";
import { changeIcons } from "../../_redux/slices/navSlice";
import { useParams } from "react-router";
import axios from "axios";
import { useTranslation } from "react-i18next";
// import { addToCart } from "../../_functions/cart";
import { useUserCart } from '../../User/hooks/useUserCart';
import { quantity } from "cart-localstorage";
import { Price } from "../../components/__simple/_price";
import { toast } from "react-toastify";
import _ from 'lodash';
import { useUserFavorite } from '../../User/hooks/useUserFavorite';
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";
import { shareMaker } from "../../utils/shareDataMaker";
import { numberFormat } from "../../utils/currencyFormatter";
import CustomTabbyTamara from "../../components/customTabbyTamara";
import SimpleButton from "../../components/_input/SimpleButton";
import { ServiceBox } from "../../components/serviceBox";
import { getData, User_Data_Key } from "../../utils/localStorageManager";

SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export function EpartsDetailPage(props) {
    const { changeFavoriteItemState, isFavoriteItem } = useUserFavorite();

    const { removecartItems, changecartItem } = useUserCart();
    const [lang, setlang] = useState(localStorage.getItem('i18nextLng'));
    const languageDir = 'language-' + lang;
    const rateRef = useRef(null);
    const carRef = useRef(null);
    const [car, setCar] = useState("active");
    const [rate, setRate] = useState("hidden");
    const [Ava, SetAva] = useState([]);
    const [Dilev, SetDilev] = useState([]);
    const [counter, setCounter] = useState(1);
    const [sharerInfo, setSharerInfo] = useState({
        url: '',
        hashTage: '',
        title: ''
    });

    let { ProductId } = useParams();
    const { t: trans } = useTranslation(["eparts", "inputs", "info", "model", "new"]);
    const GetUrl = config.baseURL + 'details';
    const [PWD, setPWD] = useState([]);
    const [PWR, setPWR] = useState([]);
    const [AttachImg, setiAttch] = useState([]);
    //-------------------------------------------------------------------------
    const [favorite, setFavorite] = useState(false);

    const [indexPanel, setIndexPanel] = useState("1000");
    const [terms, setTerms] = useState("");
    const [checkCompatibility, setCheckCompatibility] = useState({});

    function getpric(NetSalePrice, SalesPriceBefor) {
        if (SalesPriceBefor) {
            return (
                <div className="slide-info-text-price 30 space-x-4 space-x-reverse">
                    <span className={"text-14 mx-1"}>{trans("info:product_price")}</span>
                    <span className={"price  text-20 mx-2"}><Price price={NetSalePrice} /></span>
                    <span className={"old-price  text-20 mx-3"}>{SalesPriceBefor}{trans("common:rial")}</span>
                </div>);
        } else {
            return (
                <div>
                    <span className={"text-14 mx-3"}>{trans("info:product_price")}</span>
                    <span className={"price  text-20"}><Price price={NetSalePrice} /></span>
                </div>);
        }
    }

    const getPrice = (NetSalePrice, SalesPriceDiscount) => {
        if (SalesPriceDiscount) {
            return (
                <div className="flex">
                    <div className="flex-grow-0 text-start text-4xl font-bold text-primary">
                        <Price price={NetSalePrice} />
                    </div>
                    <div className="flex-grow-0 text-center text-3xl text-red-600 line-through px-8">
                        {SalesPriceDiscount}{trans("common:rial")}
                    </div>
                    <div className="flex-grow-0 px-8">
                        <div className="border border-gray-500 rounded-lg py-4"></div>
                    </div>

                    <div className="flex-grow text-start text-3xl">
                        {trans("eparts:price-includes-VAT")}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="flex">
                    <div className="flex-grow-0 text-start text-4xl font-bold text-primary">
                        <Price price={NetSalePrice} />
                    </div>

                    <div className="flex-grow-0 px-8">
                        <div className="border border-gray-500 rounded-lg py-4"></div>
                    </div>

                    <div className="flex-grow text-start text-3xl">
                        {trans("eparts:price-includes-VAT")}
                    </div>
                </div>
            );
        }
    }

    const getCatNameUrl = ({ guid }) => {
        // func_GetCatNameByGuidEPart
        try {
            const body = {
                "name": "func_GetCatNameByGuidEPart",
                "values": {
                    "guid": guid
                }
            };

            axios.request({
                url: config.baseURL + 'func',
                data: body, method: "POST", responseType: "json"
            })
                .then(function (response) {
                    if (response.data && response.data.length > 0) {
                        console.info("func_GetCatNameByGuidEPart:data", response.data[0]);
                        setCatNameUrl(response?.data[0]);
                    }
                })
                .catch(function (error) {
                    // console.warn("func_GetNameCatsByIdEPart:catch", error);
                    throw error;
                });
        } catch (ex) {
            console.warn("func_GetCatNameByGuidEPart:catch", ex);
            debugger
        }
    };


    function RequsetProductsWithDetails() {
        const Body = {
            "name": "site_FeatchPartsDetails",
            "values": {
                "GUID": ProductId
            }
        }
        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // console.log(response.data.ApiObjectData);
                setTimeout(() => (setPWD(response.data)), 100);

                if (response.data[0].ProductImage) {
                    SetImgMain(response.data[0].ProductImage
                        // ? "data:image/png;base64," + response.data[0].ProductImage
                        ? config.mainURL + response.data[0].ProductImage
                        : lang.toLowerCase() === 'ar'
                            ? "/assets/images/eparts/PARTS_TOYOTA-AR-no-img.png"
                            : "/assets/images/eparts/PARTS_TOYOTA-EN-no-img.png"
                    );
                }

                const epartsInfo = response.data[0];
                const _sharerInfo = { ...sharerInfo };

                if (epartsInfo) {
                    const { DescriptionAr, DescriptionEn, CategiorAr, CategiorEn, NetSalePrice, ProductID } = epartsInfo;
                    const {
                        url,
                        title,
                        hashTag
                    } = shareMaker(`/eparts/details/${ProductId}`, `${(DescriptionAr ?? '') + '-' + numberFormat(NetSalePrice ?? 0)}`, '');
                    _sharerInfo.title = title;
                    _sharerInfo.url = url;
                    _sharerInfo.hashTage = hashTag;
                    setSharerInfo(_sharerInfo);
                    setProductNo(ProductID);
                }
                getCatNameUrl({ guid: ProductId });
                // setTimeout(() => (RequsetProductsWithAlternatives(response.data[0].ProductID)), 100);
                setTimeout(() => (RequsetProductsWithAlternatives(ProductId)), 100);
                getTermsPart({ guid: ProductId });
                getCheckCompatiblityEPart({ guid: ProductId });
                setTimeout(() => (RequsetAttch(response.data[0].ProductID)), 100);
                setTimeout(() => (RequsetAva(response.data[0].ProductID)), 100);
            })
            .catch(function (error) {
                console.log(error);
            });


    }

    function RequsetProductsWithAlternatives(ID) {
        // const Body = {
        //     "name": "Site_FeatchPartAlternative",
        //     "values": {
        //         "ProductID": ID
        //     }
        // }
        const Body = {
            "name": "Site_FeatchPartSpecial",
            "values": {
                // "GUID": "0fff1d04-5bd4-4794-b2c0-8b5e3adaf2d7"
                "GUID": ID
            }
        }
        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // console.log(response.data.ApiObjectData);
                setTimeout(setPWR(response.data), 1000);

            })
            .catch(function (error) {
                console.log(error);
            });


    }

    const getTermsPart = ({ guid }) => {
        let body = {
            "name": "func_getTermsPart",
            "values": {
                "GUID": guid
                // "GUID": "0fff1d04-5bd4-4794-b2c0-8b5e3adaf2d7"
            }
        };

        axios.request({
            url: config.baseURL + 'func',
            data: body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // console.log(response.data.ApiObjectData);
                if (response?.data && response?.data.length > 0) {

                    console.log("func_getTermsPart: ", response.data);

                    if (lang.toLowerCase() == "ar") {
                        if (response?.data[0] && response?.data[0]?.termsAr)
                            setTerms(response?.data[0]?.termsAr);
                    } else {
                        if (response?.data[0] && response?.data[0]?.termsEn)
                            setTerms(response?.data[0]?.termsEn);
                    }
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const getCheckCompatiblityEPart = ({ guid }) => {
        let body = {
            "name": "func_CheckCompatiblityEPart",
            "values": {
                "GUID": guid
                // "GUID": "0fff1d04-5bd4-4794-b2c0-8b5e3adaf2d7"
            }
        };

        axios.request({
            url: config.baseURL + 'func',
            data: body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                if (response?.data && response.data.length > 0) {

                    console.log("getCheckCompatiblityEPart: ", response.data);
                    setCheckCompatibility(response?.data[0]);
                }

            })
            .catch(function (error) {
                console.log("getCheckCompatiblityEPart: ", error);
            });
    };

    function RequsetAttch(ID) {
        const Body = {
            "name": "Site_FeatchPartImage",
            "values": {
                "productID": ID
            }
        }
        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // console.log(response.data.ApiObjectData);
                setTimeout(setiAttch(response.data), 1000);


            })
            .catch(function (error) {
                setiAttch([]);
            });


    }

    function RequsetAva(ID) {
        const Body = {
            "name": "site_FeatchPartLocations",
            "values": {
                "productID": ProductId
            }
        }
        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        }).then(function (response) {
            // console.log(response.data.ApiObjectData);
            setTimeout(SetAva(response.data), 100);
        })
            .catch(function (error) {
                console.log(error);
            });


    }

    function RequsetDilevaryTime() {
        const Body = {
            "object": "Web_DeliveryTime",
            "ObjectSettings": {
                "MetaData": false
            }

        }

        axios.request({
            url: GetUrl,
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // console.log(response.data.ApiObjectData);
                setTimeout(SetDilev(response.data.ApiObjectData), 1000);

            })
            .catch(function (error) {
                console.log(error);
            });


    }

    const [DilevryTime, SetDilevry] = useState("");

    function DilevryTimeAccounting(option) {
        console.log(option);
        let dateNow = new Date();
        const Div = Dilev.find(f => f.DeliveryTimeId === option.value);
        if (option && option.value !== "0") {
            dateNow.setDate(Div.NumberDays);
        }

        if (lang === "en") {


            SetDilevry("day/s:" + dateNow.getDate() + ":hour:" + Div.NumbeHours);

        } else {
            SetDilevry(dateNow.getDate() + "ايام/يوم:" + ":" + Div.NumbeHours + "-ساعة");
        }

    }

    const dispatch = useDispatch();

    useEffect(() => {
        handleCalculateRatingStars();
        RequsetProductsWithDetails();
        RequsetDilevaryTime();
    }, [ProductId]);

    useEffect(() => {

        dispatch(
            changeIcons({ iconsBlack: true })
        )

        return () => {
            dispatch(
                changeIcons({ iconsBlack: false })
            )
        }
    }, []);

    const [favoriteProducts, setFavoriteProducts] = useState([]);
    // to fetch the user favorites
    const fetchUserFavorites = async () => {
        let _favorites = [];
        try {
            const isLogin = getData('isAuthenticated');
            if (isLogin) {
                const { id } = JSON.parse(getData(User_Data_Key, true));
                const userID = Number(id.toString().split('_')[1]);
                const response = await axios.post(
                    config.baseURL + 'func',
                    {
                        "Name": "SiteFetchUserFavorites",
                        "values": {
                            "userID": userID
                        }
                    }
                );
                if (response.status == 200) {
                    const favorites = response.data;
                    _favorites = _favorites.concat(favorites);
                }
            }
        } catch (err) {
            console.log(err);
        }
        return _favorites;
    };

    useEffect(() => {
        const results = fetchUserFavorites();
        setFavoriteProducts(results);
    }, []);


    function show(section) {
        setCar("hidden");
        setRate("hidden");
        switch (section) {
            case "car":
                setCar("active");
                break;
            case "rate":
                setRate("active");
                break;
            default:
                setCar("active");
        }
    }

    function count(num) {
        if (num === -1 && counter > 1)
            setCounter(counter + num);
        else if (num === 1 && counter < 100)
            setCounter(counter + num);
    }

    const [imagePath, SetImgMain] = useState(lang.toLowerCase() == 'ar'
        ? "/assets/images/eparts/PARTS_TOYOTA-AR-no-img.png"
        : "/assets/images/eparts/PARTS_TOYOTA-EN-no-img.png"
    );
    //-------------------------------------------------------------------
    const [locationName, setLocationName] = useState('');
    const [locationDistance, setLocationDistance] = useState(null);
    const [showSharers, setShowSharers] = useState(false);
    const [productNo, setProductNo] = useState('');
    const [ratingValues, setRatingValues] = useState({
        stars: 0,
        comments: 0
    });

    const [catNameUrl, setCatNameUrl] = useState({});
    // to calculate the distance bewtten two coordinates points
    const handleGetDistance = (value) => {
        try {
            if (value == '' || value == '0') {
                setLocationName('');
                setLocationDistance(null);
                return;
            }
            const { Address, Longitude, Latitude } = Ava.find((item) => {
                return item.GUID == value
            });
            if (navigator.onLine) {
                if (Latitude != null && Longitude != null) {
                    navigator.geolocation.getCurrentPosition((position) => {

                        const currentPosition = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        }
                        const result = getDistance(Latitude, currentPosition.lat, Longitude, currentPosition.lng).toFixed(5);
                        setLocationDistance(result);
                    });
                } else {
                    setLocationDistance(null);
                }
            } else {
                toast.warning(trans('info:no_internet_connect'))
            }
            setLocationName(Address ?? '');
        } catch (error) {
            console.log(error);
        }
    };
    // to calculate the distance 
    const getDistance = (lat1, lat2, long1, long2) => {
        const y = Math.abs(lat2 - lat1);
        const x = Math.abs(long2 - long1);
        return Math.sqrt((x * x + y * y));
    };
    // to share the product
    const handleShareItem = () => {
        setShowSharers(!showSharers);
    };
    // to calculate ratings
    const handleCalculateRatingStars = () => {
        axios.post(
            config.baseURL + 'func',
            {
                "Name": "SiteFetchRatingStarsBySparePartProduct",
                "Values": {
                    "GUID": `${ProductId}`
                }
            }
        ).then(response => {
            const value = { ...ratingValues };
            const ratingsGroupsData = response.data;
            const results = ratingsGroupsData.map(item => {
                return { star: item.Stars, total: item.Total, spe: (item.Stars * item.Total), comments: item.Comments }
            });
            const totalComments = Math.ceil((_.sum(results.map(item => {
                return item.comments
            })) / 2));
            const totalOfSpe = _.sum(results.map(item => {
                return item.spe
            }));
            const totalOfStars = _.sum(results.map(item => {
                return item.total
            }));
            const finalTotal = Math.floor(totalOfSpe / totalOfStars);
            value.stars = finalTotal;
            value.comments = totalComments;
            setRatingValues(value);
        }).catch(error => {
            console.log(error);
        });
    };

    const handelAddToCart = ({ part }) => {

        let objectCart =
        {
            id: part.ProductID,
            productID: part.ProductID,
            name: part.DescriptionAr,
            nameEn: part.DescriptionEn,
            price: part.NetSalePrice,
            image: imagePath,
            Type: "part",
        };

        console.log("objectCart: ", objectCart)

        changecartItem(part?.ProductID, null, null, 1, null, null, objectCart);

        //
        // let objectCart =
        //     part.SalesPriceDiscount > 0
        //         ?
        //         {
        //             id: part?.ProductID,
        //             productID: part?.ProductID,
        //             name: part?.DescriptionAr,
        //             nameEn: part?.DescriptionEn,
        //             price: part?.SalesPriceDiscount,
        //             image: part?.imagePath,
        //             Type: "part",
        //         } :
        //         {
        //             id: part?.ProductID,
        //             productID: part?.ProductID,
        //             name: part?.DescriptionAr,
        //             price: part?.NetSalePrice,
        //             image: imagePath,
        //             Type: "part"
        //         }
        //         changecartItem(part?.ProductID, null, null, 1, null, null, objectCart);
    }

    return (
        <Fragment>

            <div className="container mb-10 mt-xs">

                {
                    (catNameUrl?.catId && catNameUrl?.catSubId && catNameUrl?.catSubSubIdd
                        && catNameUrl?.catAr && catNameUrl.catEn
                        && catNameUrl?.subCatAr && catNameUrl?.subCatEn
                        && catNameUrl?.subSubCatAr && catNameUrl?.subSubCatEn
                    )
                        ? <Breadcrumb className={"mt-16 "}
                            links={[
                                {
                                    name: trans("eparts:EpartTitel"),
                                    to: "/eparts"
                                },
                                {
                                    name: catNameUrl?.catId
                                        ? lang.toLowerCase() == 'ar' ? catNameUrl?.catAr : catNameUrl.catEn
                                        : "",
                                    // name: trans("eparts:details")
                                    to: `/eparts/sereach?CatMain=${catNameUrl?.catId}&SuPCat=null&SSuPCat=null`
                                },

                                {
                                    name: catNameUrl?.catId && catNameUrl?.catSubId
                                        ? lang.toLowerCase() == 'ar' ? catNameUrl?.subCatAr : catNameUrl?.subCatEn
                                        : "",
                                    // name: trans("eparts:details")
                                    to: `/eparts/sereach?CatMain=${catNameUrl?.catId}&SuPCat=${catNameUrl?.catSubId}&SSuPCat=null`
                                },

                                {
                                    name: catNameUrl?.catId && catNameUrl?.catSubId && catNameUrl.catSubSubId
                                        ? lang.toLowerCase() == 'ar' ? catNameUrl?.subSubCatAr : catNameUrl?.subSubCatEn
                                        : "",
                                    // name: trans("eparts:details")
                                    to: `/eparts/sereach?CatMain=${catNameUrl?.catId}&SuPCat=${catNameUrl?.catSubId}&SSuPCat=${catNameUrl.catSubSubId}`
                                },
                                {
                                    name: lang.toLowerCase() == "en" ? PWD[0]?.DescriptionEn : PWD[0]?.DescriptionAr
                                }
                            ]}
                        />
                        : (catNameUrl?.catId && catNameUrl?.catSubId
                            && catNameUrl?.catAr && catNameUrl?.catEn
                            && catNameUrl?.subCatAr && catNameUrl?.subCatEn
                        )
                            ? <Breadcrumb className={"mt-16 "}
                                links={[
                                    {
                                        name: trans("eparts:EpartTitel"),
                                        to: "/eparts"
                                    },
                                    {
                                        name: catNameUrl?.catId
                                            ? lang.toLowerCase() == 'ar' ? catNameUrl?.catAr : catNameUrl.catEn
                                            : "",
                                        // name: trans("eparts:details")
                                        to: `/eparts/sereach?CatMain=${catNameUrl?.catId}&SuPCat=null&SSuPCat=null`
                                    },

                                    {
                                        name: catNameUrl?.catId && catNameUrl?.catSubId
                                            ? lang.toLowerCase() == 'ar' ? catNameUrl?.subCatAr : catNameUrl?.subCatEn
                                            : null,
                                        // name: trans("eparts:details")
                                        to: `/eparts/sereach?CatMain=${catNameUrl?.catId}&SuPCat=${catNameUrl?.catSubId}&SSuPCat=null`
                                    },

                                    {
                                        name: lang.toLowerCase() == "en" ? PWD[0]?.DescriptionEn : PWD[0]?.DescriptionAr
                                    }
                                ]}
                            />
                            : (catNameUrl?.catId
                                && catNameUrl?.catAr && catNameUrl?.catEn
                            )
                                ? <Breadcrumb className={"mt-16 "}
                                    links={[
                                        {
                                            name: trans("eparts:EpartTitel"),
                                            to: "/eparts"
                                        },
                                        {
                                            name: catNameUrl?.catId
                                                ? lang.toLowerCase() == 'ar' ? catNameUrl?.catAr : catNameUrl.catEn
                                                : "",
                                            // name: trans("eparts:details")
                                            to: `/sereach?CatMain=${catNameUrl?.catId}&SuPCat=null&SSuPCat=null`
                                        },

                                        {
                                            name: lang.toLowerCase() == "en" ? PWD[0]?.DescriptionEn : PWD[0]?.DescriptionAr
                                        }
                                    ]}
                                />
                                : <Breadcrumb className={"mt-16 "}
                                    links={[
                                        {
                                            name: trans("eparts:EpartTitel"),
                                            to: "/eparts"
                                        },
                                        // {
                                        //     name: catNameUrl?.catId
                                        //         ? lang.toLowerCase() == 'ar' ? catNameUrl?.catAr : catNameUrl.catEn
                                        //         : ""
                                        //     // name: trans("eparts:details")
                                        // },
                                        {
                                            name: lang.toLowerCase() == "en" ? PWD[0]?.DescriptionEn : PWD[0]?.DescriptionAr
                                        }
                                    ]}
                                />
                }

                {/*{*/}
                {/*    catNameUrl?.catId && (*/}
                {/*        <Breadcrumb className={"mt-16 "}*/}
                {/*                    links={[*/}
                {/*                        {*/}
                {/*                            name: trans("eparts:EpartTitel"),*/}
                {/*                            to: "/eparts"*/}
                {/*                        },*/}
                {/*                        {*/}
                {/*                            name: catNameUrl?.catId*/}
                {/*                                ? lang.toLowerCase() == 'ar' ? catNameUrl?.catAr : catNameUrl.catEn*/}
                {/*                                : ""*/}
                {/*                            // name: trans("eparts:details")*/}
                {/*                        },*/}
                {/*                    ]}*/}
                {/*        />*/}
                {/*    )*/}
                {/*}*/}

                {/*{*/}
                {/*    catNameUrl?.catId && catNameUrl?.catSubId && (*/}
                {/*        <Breadcrumb className={"mt-16 "}*/}
                {/*                    links={[*/}
                {/*                        {*/}
                {/*                            name: trans("eparts:EpartTitel"),*/}
                {/*                            to: "/eparts"*/}
                {/*                        },*/}
                {/*                        {*/}
                {/*                            name: catNameUrl?.catId*/}
                {/*                                ? lang.toLowerCase() == 'ar' ? catNameUrl?.catAr : catNameUrl.catEn*/}
                {/*                                : ""*/}
                {/*                            // name: trans("eparts:details")*/}
                {/*                        },*/}

                {/*                        {*/}
                {/*                            name: catNameUrl?.catId && catNameUrl?.catSubId*/}
                {/*                                ? lang.toLowerCase() == 'ar' ? catNameUrl?.subCatAr : catNameUrl?.subCatEn*/}
                {/*                                : ""*/}
                {/*                            // name: trans("eparts:details")*/}
                {/*                        },*/}
                {/*                    ]}*/}
                {/*        />*/}
                {/*    )*/}
                {/*}*/}

                {/*{*/}
                {/*    catNameUrl?.catId && catNameUrl?.catSubId && catNameUrl.catSubSubIdd && (*/}
                {/*        <Breadcrumb className={"mt-16 "}*/}
                {/*                    links={[*/}
                {/*                        {*/}
                {/*                            name: trans("eparts:EpartTitel"),*/}
                {/*                            to: "/eparts"*/}
                {/*                        },*/}
                {/*                        {*/}
                {/*                            name: catNameUrl?.catId*/}
                {/*                                ? lang.toLowerCase() == 'ar' ? catNameUrl?.catAr : catNameUrl.catEn*/}
                {/*                                : ""*/}
                {/*                            // name: trans("eparts:details")*/}
                {/*                        },*/}

                {/*                        {*/}
                {/*                            name: catNameUrl?.catId && catNameUrl?.catSubId*/}
                {/*                                ? lang.toLowerCase() == 'ar' ? catNameUrl?.subCatAr : catNameUrl?.subCatEn*/}
                {/*                                : ""*/}
                {/*                            // name: trans("eparts:details")*/}
                {/*                        },*/}

                {/*                        {*/}
                {/*                            name: catNameUrl?.catId && catNameUrl?.catSubId && catNameUrl.catSubSubId*/}
                {/*                                ? lang.toLowerCase() == 'ar' ? catNameUrl?.subSubCatAr : catNameUrl?.subSubCatEn*/}
                {/*                                : ""*/}
                {/*                            // name: trans("eparts:details")*/}
                {/*                        }*/}
                {/*                    ]}*/}
                {/*        />*/}
                {/*    )*/}
                {/*}*/}


                {/*<Breadcrumb className={"mt-16 "}*/}
                {/*            links={[*/}
                {/*                {*/}
                {/*                    name: trans("eparts:EpartTitel"),*/}
                {/*                    to: "/eparts"*/}
                {/*                },*/}
                {/*                {*/}
                {/*                    name: catNameUrl?.catId*/}
                {/*                        ? lang.toLowerCase() == 'ar' ? catNameUrl?.catAr : catNameUrl.catEn*/}
                {/*                        : ""*/}
                {/*                    // name: trans("eparts:details")*/}
                {/*                },*/}

                {/*                {*/}
                {/*                    name: catNameUrl?.catId && catNameUrl?.catSubId*/}
                {/*                        ? lang.toLowerCase() == 'ar' ? catNameUrl?.subCatAr : catNameUrl?.subCatEn*/}
                {/*                        : ""*/}
                {/*                    // name: trans("eparts:details")*/}
                {/*                },*/}

                {/*                {*/}
                {/*                    name: catNameUrl?.catId && catNameUrl?.catSubId && catNameUrl.catSubSubId*/}
                {/*                        ? lang.toLowerCase() == 'ar' ? catNameUrl?.subSubCatAr : catNameUrl?.subSubCatEn*/}
                {/*                        : ""*/}
                {/*                    // name: trans("eparts:details")*/}
                {/*                }*/}
                {/*            ]}*/}
                {/*/>*/}

                {/*<div className={"part-detail " + languageDir}>*/}
                <div
                    className={"container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2  gap-4 " + languageDir}>
                    {/*الصورة*/}
                    <div className="part-detail-imgs ">
                        <div className="part-detail-imgs-main">
                            <div className="part-detail-imgs-img">
                                <img className={"object-full object-contain"}
                                    src={imagePath} alt={imagePath.toString()}
                                    loading={"lazy"}
                                    onError={(e) => e.target.style.display = "none"}
                                />
                            </div>
                        </div>
                        {
                            AttachImg && AttachImg.length > 0 &&
                            <div className={"part-detail-imgs-others"}>
                                {AttachImg.map(Att => (
                                    <div className="part-detail-imgs-img">
                                        <img
                                            className={"object-full object-contain"}
                                            // src={"data:image/png;base64," + Att.ProductImage}
                                            src={config.mainURL + Att.ProductImage}
                                            alt={Att?.ProductImage?.toString()}
                                            loading={"lazy"}
                                            onError={(e) => e.target.style.display = "none"}
                                        />
                                    </div>
                                )
                                )}

                            </div>
                        }

                    </div>

                    {
                        // 
                        PWD.map(D => {
                            return (
                                <>
                                    {
                                        <div className="">

                                            {/*<div className="part-detail-info-num mb-6">*/}
                                            {/*    <span> {trans("eparts:product_id")}: </span>*/}
                                            {/*    <h3 className={"font-bold"}>{D.ProductNo} </h3>*/}

                                            {/*</div>*/}

                                            {/*<div className="part-detail-info-desc">*/}
                                            {/*    <p className="text-20">{lang.toLowerCase() == "en" ? D.DescriptionEn : D.DescriptionAr}</p>*/}
                                            {/*</div>*/}

                                            {/*<div className="part-detail-info-rate my-6">*/}
                                            {/*    <PartRating*/}
                                            {/*        readonly={true}*/}
                                            {/*        intialValue={ratingValues.stars}*/}
                                            {/*    />*/}
                                            {/*    <div className="rating-info">*/}
                                            {/*    <span*/}
                                            {/*        className={"text-16 text-gray-dark"}>{ratingValues.stars}</span>*/}
                                            {/*        <div className={"rating-divider"}></div>*/}

                                            {/*        <div className={'text-16 text-gray-dark'}>*/}
                                            {/*            {ratingValues.comments} {trans("eparts:mkj")}*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}

                                            {/*<div className="part-detail-info-price">*/}
                                            {/*    {getpric(D.NetSalePrice, D.SalesPriceDiscount)}*/}
                                            {/*</div>*/}

                                            {/*<div className="part-detail-info-spec py-8">*/}
                                            {/*    <h4 className="text-16 text-gray-dark mb-10">{trans("eparts:Specifications")}</h4>*/}
                                            {/*    <div>*/}
                                            {/*        <div className={"info-spec"}>*/}
                                            {/*            <div className="info-spec-key">{trans("eparts:length")}:</div>*/}

                                            {/*            <div*/}
                                            {/*                className="info-spec-value">{D.length} {trans("eparts:mm")}</div>*/}
                                            {/*        </div>*/}
                                            {/*        */}
                                            {/*        <div className={"info-spec"}>*/}
                                            {/*            <div className="info-spec-key">{trans("eparts:wheth")}:</div>*/}
                                            {/*            <div*/}
                                            {/*                className="info-spec-value">{D.width} {trans("eparts:mm")}</div>*/}
                                            {/*        </div>*/}
                                            {/*        */}
                                            {/*        <div className={"info-spec"}>*/}
                                            {/*            <div className="info-spec-key">{trans("eparts:height")}:</div>*/}
                                            {/*            <div*/}
                                            {/*                className="info-spec-value">{D.height} {trans("eparts:mm")}</div>*/}
                                            {/*        </div>*/}
                                            {/*        */}
                                            {/*        <div className={"info-spec"}>*/}
                                            {/*            <div className="info-spec-key">{trans("eparts:Weight")}:</div>*/}
                                            {/*            <div*/}
                                            {/*                className="info-spec-value">{D.Weight} {trans("eparts:mm")}</div>*/}
                                            {/*        </div>*/}

                                            {/*    </div>*/}
                                            {/*</div>*/}

                                            <div>
                                                {
                                                    !D?.BrandID && (
                                                        <div className={
                                                            `text-30 font-bold mb-8 h-16 uppercase ${D?.BrandID == '2'
                                                                ? "text-black"
                                                                : D?.BrandID == '1'
                                                                    ? "text-red-600"
                                                                    : "text-red-600"
                                                            }`
                                                        }>
                                                            {
                                                                D?.BrandID == '2'
                                                                    ? trans("eparts:lexus-key")
                                                                    : D?.BrandID == '1'
                                                                        ? trans("eparts:toyota-key")
                                                                        : trans("eparts:toyota-key")
                                                            }
                                                        </div>
                                                    )
                                                }

                                                <div className="h-16 flex flex-row">
                                                    <span className="text-24">
                                                        {lang.toLowerCase() == "en" ? D.DescriptionEn : D.DescriptionAr}
                                                    </span>

                                                    {
                                                        D.ProductNo && (
                                                            <span
                                                                className="mx-3 mt-5 text-16 font-bold border-b-2 border-green-700 border-opacity-30">
                                                                {"[ " + D.ProductNo.toString() + " ]"}
                                                            </span>
                                                        )
                                                    }
                                                </div>

                                                <div className="text-4xl my-8">
                                                    {getPrice(D.NetSalePrice, D.SalesPriceDiscount)}
                                                </div>

                                                <div
                                                    className="offer-button items-cente mt-10 w-100 clearfix grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-2">
                                                    <div className="w-100 ">
                                                        <SimpleButton
                                                            classes="btn btn-primary text-white text-20"
                                                            iconPath={'/assets/icons/cart.svg'}
                                                            title={trans("info:add_to_cart")}
                                                            onClick={() => {
                                                                handelAddToCart({ part: D });
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="w-100">
                                                        <SimpleButton
                                                            classes="btn bg-black hover:bg-gray-800 text-white text-20"
                                                            iconPath={Boolean(isFavoriteItem(productNo)) ? '/assets/icons/heart.png' : '/assets/icons/heart-white.svg'}
                                                            title={trans('info:add_favorite')}
                                                            onClick={(evt) => {
                                                                changeFavoriteItemState(productNo);
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="w-100"></div>
                                                </div>

                                                <div className="">
                                                    <CustomTabbyTamara price={D.NetSalePrice} />
                                                </div>

                                                {/* <div className="part-detail-info-rate my-16 ">
                                                    <PartRating
                                                        readonly={true}
                                                        intialValue={ratingValues.stars}
                                                    />
                                                    <div className="rating-info">
                                                        <span
                                                            className={"text-16 text-gray-dark"}>{ratingValues.stars}</span>
                                                        <div className={"rating-divider"}></div>

                                                        <div className={'text-16 text-gray-dark'}>
                                                            {ratingValues.comments} {trans("eparts:mkj")}
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <>
                                                    {
                                                        (D.length && D.width && D.height && D.Weight) &&
                                                        (
                                                            <div
                                                                className="panel-section panel-white bg-theme-up w-100">
                                                                <div
                                                                    className={`panel-content   ${(indexPanel == "1000") ? "show-panel-info" : ""}`}>
                                                                    <div className="panel-content-top px-5"

                                                                        onClick={(e) => setIndexPanel(indexPanel == "1000" ? "000" : "1000")}

                                                                    >
                                                                        {/*<h1 className="text-20 text-super-gray font-bold">{trans("offers:offer")}</h1>*/}
                                                                        <h1 className="text-20 text-super-gray font-bold">{trans("eparts:Specifications")}</h1>
                                                                        <img src="/assets/icons/arrow.svg"
                                                                            alt={"arrow"}
                                                                            loading={"lazy"}
                                                                            onError={(e) => e.target.style.display = "none"}
                                                                        />
                                                                    </div>

                                                                    <div className="panel-content-body">
                                                                        <div className="mx-10 my-10">

                                                                            {
                                                                                // offer?.content ?
                                                                                <div
                                                                                    className={"new-lines offer-detail-data"}>

                                                                                    <ul>

                                                                                        {
                                                                                            (D.length && D.length != 0 && D.length != 0.0) && (
                                                                                                <li>
                                                                                                    <div
                                                                                                        className={"info-spec"}>
                                                                                                        <div
                                                                                                            className="info-spec-key">{trans("eparts:length")}:
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="info-spec-value">{D.length} {trans("eparts:mm")}</div>
                                                                                                    </div>
                                                                                                </li>
                                                                                            )
                                                                                        }

                                                                                        {
                                                                                            (D.width && D.width != 0 && D.width != 0.0) && (
                                                                                                <li>
                                                                                                    <div
                                                                                                        className={"info-spec"}>
                                                                                                        <div
                                                                                                            className="info-spec-key">{trans("eparts:wheth")}:
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="info-spec-value">{D.width} {trans("eparts:mm")}</div>
                                                                                                    </div>
                                                                                                </li>
                                                                                            )
                                                                                        }

                                                                                        {
                                                                                            (D.height && D.height != 0 && D.height != 0.0) && (
                                                                                                <li>
                                                                                                    <div
                                                                                                        className={"info-spec"}>
                                                                                                        <div
                                                                                                            className="info-spec-key">{trans("eparts:height")}:
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="info-spec-value">{D.height} {trans("eparts:mm")}</div>
                                                                                                    </div>
                                                                                                </li>
                                                                                            )
                                                                                        }

                                                                                        {
                                                                                            (D.Weight && D.Weight != 0 && D.Weight != 0.0) && (
                                                                                                <li>
                                                                                                    D.Weight
                                                                                                    <div
                                                                                                        className={"info-spec"}>
                                                                                                        <div
                                                                                                            className="info-spec-key">{trans("eparts:Weight")}:
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="info-spec-value">{D.Weight} {trans("eparts:mm")}</div>
                                                                                                    </div>
                                                                                                </li>
                                                                                            )
                                                                                        }
                                                                                    </ul>

                                                                                </div>
                                                                                //     : null
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </>

                                            </div>

                                        </div>
                                    }
                                </>
                            );


                            // if (lang === "en") {
                            //     return (
                            //         <div className="part-detail-info">
                            //             <div className="part-detail-info-num mb-6">
                            //                 <span> {trans("eparts:product_id")}: </span>
                            //                 <h3 className={"font-bold"}>{D.ProductNo} </h3>
                            //
                            //             </div>
                            //
                            //             <div className="part-detail-info-desc">
                            //                 <p className="text-20">{D.DescriptionEn}</p>
                            //             </div>
                            //
                            //             <div className="part-detail-info-rate my-6">
                            //                 <PartRating
                            //                     readonly={true}
                            //                     intialValue={ratingValues.stars}
                            //                 />
                            //                 <div className="rating-info">
                            //                     <span className={"text-16 text-gray-dark"}>{ratingValues.stars}</span>
                            //                     <div className={"rating-divider"}></div>
                            //
                            //                     <div className={'text-16 text-gray-dark'}>
                            //                         {ratingValues.comments} {trans("eparts:mkj")}
                            //                     </div>
                            //                 </div>
                            //             </div>
                            //
                            //             <div className="part-detail-info-price">
                            //
                            //                 {getpric(D.NetSalePrice, D.SalesPriceDiscount)}
                            //             </div>
                            //
                            //             <div className="part-detail-info-spec py-8">
                            //                 <h4 className="text-16 text-gray-dark mb-10">{trans("eparts:Specifications")}</h4>
                            //                 <div>
                            //                     <div className={"info-spec"}>
                            //                         <div className="info-spec-key">{trans("eparts:length")}:</div>
                            //
                            //                         <div
                            //                             className="info-spec-value">{D.length} {trans("eparts:mm")}</div>
                            //                     </div>
                            //                     <div className={"info-spec"}>
                            //                         <div className="info-spec-key">{trans("eparts:wheth")}:</div>
                            //                         <div
                            //                             className="info-spec-value">{D.width} {trans("eparts:mm")}</div>
                            //                     </div>
                            //                     <div className={"info-spec"}>
                            //                         <div className="info-spec-key">{trans("eparts:height")}:</div>
                            //                         <div
                            //                             className="info-spec-value">{D.height} {trans("eparts:mm")}</div>
                            //                     </div>
                            //                     <div className={"info-spec"}>
                            //                         <div className="info-spec-key">{trans("eparts:Weight")}:</div>
                            //                         <div
                            //                             className="info-spec-value">{D.Weight} {trans("eparts:mm")}</div>
                            //                     </div>
                            //                 </div>
                            //             </div>
                            //
                            //         </div>
                            //     );
                            // } else {
                            //     return (
                            //         <div className="part-detail-info">
                            //            
                            //             <div className="part-detail-info-num mb-6">
                            //                 <span> {trans("eparts:product_id")}: </span>
                            //                 <h3 className={"font-bold"}>{D.ProductNo} </h3>
                            //
                            //             </div>
                            //            
                            //             <div className="part-detail-info-desc">
                            //                 <p className="text-20">{D.DescriptionAr}</p>
                            //             </div>
                            //            
                            //             {
                            //                 // التقييم
                            //                 <div className="part-detail-info-rate my-6">
                            //
                            //                     <PartRating
                            //                         readonly={true}
                            //                         intialValue={ratingValues.stars}
                            //                     />
                            //
                            //                     <div className="rating-info">
                            //                     <span
                            //                         className={"text-16 text-gray-dark"}>{ratingValues.stars}</span>
                            //                         <div className={"rating-divider"}></div>
                            //
                            //                         <div className={'text-16 text-gray-dark'}>
                            //                             {ratingValues.comments} {trans("eparts:mkj")}
                            //                         </div>
                            //                     </div>
                            //                 </div>
                            //             }
                            //             <div className="part-detail-info-price">
                            //                 <div className="price flex items-center">
                            //                     {getpric(D.NetSalePrice, D.SalesPriceDiscount)}
                            //                 </div>
                            //
                            //             </div>
                            //             <div className="part-detail-info-spec py-8">
                            //                 <h4 className="text-16 text-gray-dark mb-10">{trans("eparts:Specifications")}</h4>
                            //                 <div>
                            //                     <div className={"info-spec"}>
                            //                         <div className="info-spec-key">{trans("eparts:length")}:</div>
                            //
                            //                         <div
                            //                             className="info-spec-value">{D.length} {trans("eparts:mm")}</div>
                            //                     </div>
                            //                     <div className={"info-spec"}>
                            //                         <div className="info-spec-key">{trans("eparts:wheth")}:</div>
                            //                         <div
                            //                             className="info-spec-value">{D.width} {trans("eparts:mm")}</div>
                            //                     </div>
                            //                     <div className={"info-spec"}>
                            //                         <div className="info-spec-key">{trans("eparts:height")}:</div>
                            //                         <div
                            //                             className="info-spec-value">{D.height} {trans("eparts:mm")}</div>
                            //                     </div>
                            //                     <div className={"info-spec"}>
                            //                         <div className="info-spec-key">{trans("eparts:Weight")}:</div>
                            //                         <div
                            //                             className="info-spec-value">{D.Weight} {trans("eparts:mm")}</div>
                            //                     </div>
                            //                 </div>
                            //             </div>
                            //
                            //         </div>
                            //     );
                            // }
                        }
                        )
                    }

                    {
                        // <div className="part-detail-options">
                        //     <div className="options">
                        //         <div className={`options-like  ${lang == 'ar' ? 'options-like-r' : 'options-like-l'}`}>
                        //             <LikeButton
                        //                 favorite={Boolean(isFavoriteItem(productNo))}
                        //                 long={true}
                        //                 onToggle={() => {
                        //                     changeFavoriteItemState(productNo)
                        //                 }}
                        //             />
                        //         </div>
                        //         <div>
                        //             <div className="option-title">   {trans("eparts:dateNithing")}</div>
                        //             <DropDownInput
                        //                 className={"ml-6 mr-16"}
                        //                 options={[
                        //                     ...Dilev.map(A => {
                        //                         if (lang === "ar") {
                        //                             return {value: A.DeliveryTimeId, text: A.SIteNameAr};
                        //                         } else {
                        //                             return {value: A.DeliveryTimeId, text: A.SiteNameEn};
                        //                         }
                        //
                        //                     })]}
                        //                 onfilter={DilevryTimeAccounting}
                        //
                        //             />
                        //             <p className={"mt-6 mx-6 border-radius bg-page py-4 text-16 font-bold pr-4"}>
                        //                 {trans("eparts:AccessTo")} {DilevryTime}
                        //             </p>
                        //         </div>
                        //
                        //
                        //         {PWD.map(WD => (
                        //             <div className="bg-page py-4 px-2 my-8 part-detail-options-counter">
                        //
                        //                 <div className="counter">
                        //                     <button className="counter-btn" onClick={() => count(-1)}>-</button>
                        //                     <div className="counter-num">{counter}</div>
                        //                     <button className="counter-btn" onClick={() => count(+1)}>+</button>
                        //                 </div>
                        //                 <div
                        //                     onClick={() => {
                        //
                        //                         var objectCart =
                        //                             WD.SalesPriceDiscount > 0 ?
                        //                                 {
                        //
                        //
                        //                                     id: WD?.ProductID,
                        //                                     productID: WD?.ProductID,
                        //                                     name: WD?.DescriptionAr,
                        //                                     price: WD?.SalesPriceDiscount,
                        //                                     image: imagePath,
                        //                                     Type: "part"
                        //
                        //
                        //                                     // quantity(  WD.ProductID,counter)
                        //                                 }
                        //                                 :
                        //                                 {
                        //
                        //                                     id: WD?.ProductID,
                        //                                     productID: WD?.ProductID,
                        //                                     name: WD?.DescriptionAr,
                        //                                     price: WD?.NetSalePrice,
                        //                                     image: imagePath,
                        //                                     Type: "part"
                        //
                        //
                        //                                     // quantity(  WD.ProductID,counter)
                        //                                 }
                        //
                        //                         changecartItem(WD.ProductID, null, null, counter, null, null, objectCart);
                        //
                        //
                        //                     }}
                        //
                        //                     className={"btn btn-primary text-14 px-4"}>
                        //                     {trans("eparts:AddToCart")}
                        //                     <img className={"inline filter-white size-20 pr-1"}
                        //                          src="/assets/icons/cart.svg"
                        //                          alt="hassan jameel cart "/>
                        //                 </div>
                        //             </div>
                        //         ))}
                        //
                        //
                        //         <div>
                        //             <div className="option-title"> {trans("eparts:AvaAtView")}</div>
                        //             {
                        //
                        //                 <DropDownInput
                        //                     className={"ml-6 mr-16"}
                        //                     options={[
                        //                         ...Ava.map(A => {
                        //                             if (lang === "ar") {
                        //                                 return {value: A.GUID, text: A.SitesDescriptionAr};
                        //                             } else {
                        //                                 return {value: A.GUID, text: A.SitesDescriptionEn};
                        //                             }
                        //
                        //                         })]}
                        //                     onSelect={handleGetDistance}
                        //
                        //                 />
                        //
                        //             }
                        //
                        //             <div className={"part-locations mt-10"}>
                        //                 <h3 className={"part-locations-title"}> {trans("eparts:locations-title")}</h3>
                        //                 <div className="mx-6 mt-4 part-locations-flex">
                        //                     <div>
                        //                         <div className={"part-locations-name"}>
                        //                             <img className={"filter-primary"} src="/assets/icons/location.svg"
                        //                                  alt="location"/>
                        //                             <h4 className={"text-16"}>{locationName}</h4>
                        //                         </div>
                        //                         <div
                        //                             className={"part-locations-place mr-16 mt-2  bg-page px-4 py-6 border-radius-sm"}>
                        //                             <p className={"text-16"}>
                        //                                 {
                        //                                     trans("info:ditance_note") + ' ' + (locationDistance ? (String(locationDistance) + ' ' + trans('info:km')) : '')
                        //                                 }
                        //                             </p>
                        //                             <a className={"part-locations-place-link nice-pointer"}
                        //                                onClick={handleShareItem}>
                        //                                 <img className={"object-full object-contain"}
                        //                                      src="/assets/icons/link-new-page.svg" alt=""/>
                        //                             </a>
                        //
                        //                         </div>
                        //                         {showSharers &&
                        //                             <div className=" my-4  nice-flex nice-flex-row nice-flex-center">
                        //                                 <FacebookShareButton
                        //                                     url={sharerInfo.url}
                        //                                     quote={sharerInfo.title}
                        //                                     hashtag={sharerInfo.hashTage}
                        //                                 >
                        //                                     <FacebookIcon size={36}/>
                        //                                 </FacebookShareButton>
                        //                                 <TwitterShareButton
                        //                                     url={sharerInfo.url}
                        //                                     quote={sharerInfo.title}
                        //                                     hashtag={sharerInfo.hashTage}
                        //                                 >
                        //                                     <TwitterIcon size={36}/>
                        //                                 </TwitterShareButton>
                        //                                 <WhatsappShareButton
                        //                                     url={sharerInfo.url}
                        //                                     quote={sharerInfo.title}
                        //                                     hashtag={sharerInfo.hashTage}
                        //                                 >
                        //                                     <WhatsappIcon size={36}/>
                        //                                 </WhatsappShareButton>
                        //                                 <TelegramShareButton
                        //                                     url={sharerInfo.url}
                        //                                     quote={sharerInfo.title}
                        //                                     hashtag={sharerInfo.hashTage}
                        //                                 >
                        //                                     <TelegramIcon size={36}/>
                        //                                 </TelegramShareButton>
                        //                                 <EmailShareButton
                        //                                     url={sharerInfo.url}
                        //                                     quote={sharerInfo.title}
                        //                                     hashtag={sharerInfo.hashTage}
                        //                                 >
                        //                                     <EmailIcon size={36}/>
                        //                                 </EmailShareButton>
                        //                             </div>}
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //         </div>
                        //     </div>
                        //     <div className="services">
                        //         <div className={"service"}>
                        //             <img className={"service-img filter-black"} src="/assets/icons/part/secure.svg"
                        //                  alt=""/>
                        //             <div className="service-text">
                        //                 <h4 className={"text-16"}>  {trans("new:locations-shop")}</h4>
                        //                 <img className={"info-icon "} src={"/assets/icons/info-primary.svg"}
                        //                      alt={"info"}/>
                        //             </div>
                        //         </div>
                        //         <div className={"service"}>
                        //             <img className={"service-img filter-black"} src="/assets/icons/part/replace.svg"
                        //                  alt=""/>
                        //             <div className="service-text">
                        //                 <h4 className={"text-16"}> {trans("new:locations-Replace")}</h4>
                        //                 <img className={"info-icon "} src={"/assets/icons/info-primary.svg"}
                        //                      alt={"info"}/>
                        //             </div>
                        //         </div>
                        //         <div className={"service"}>
                        //             <img className={"service-img filter-black"} src="/assets/icons/part/original.svg"
                        //                  alt=""/>
                        //             <div className="service-text">
                        //                 <h4 className={"text-16"}>{trans("new:locations-Advice")}</h4>
                        //                 <img className={"info-icon "} src={"/assets/icons/info-primary.svg"}
                        //                      alt={"info"}/>
                        //             </div>
                        //         </div>
                        //         <div className={"service"}>
                        //             <img className={"service-img filter-black"} src="/assets/icons/part/fast.svg"
                        //                  alt=""/>
                        //             <div className="service-text">
                        //                 <h4 className={"text-16"}>{trans("new:locations-Send")}</h4>
                        //                 <img className={"info-icon "} src={"/assets/icons/info-primary.svg"}
                        //                      alt={"info"}/>
                        //             </div>
                        //         </div>
                        //     </div>
                        // </div>
                    }
                </div>
            </div>

            {
                /*التوافق*/
                (checkCompatibility?.CheckCompatibility == true) && (
                    <div className="part-info-detail bg-white box-shadow py-10 mb-32 w-screen pb-20">

                        <div className="part-info-detail-btns mb-10 container">
                            <button onClick={() => {
                                show("car")
                            }} className={"text-20 text-gray " + car}>{trans("eparts:compatibility")}
                            </button>

                            {/*todo: التقييم */}
                            {/*<button onClick={() => {*/}
                            {/*    show("rate")*/}
                            {/*}} className={"text-20 text-gray " + rate}>{trans("eparts:oradar")}*/}
                            {/*</button>*/}
                        </div>

                        <div className={car}>

                            <div className="container">
                                <CarSection guidProductId={ProductId} />
                            </div>

                            <div className={rate}>
                                <RatingSection
                                    productID={ProductId}
                                />
                            </div>
                        </div>
                    </div>
                )
            }


            {
                // todo:  الشروط والاحكام 

                terms && terms != "" && (
                    <div className="container mb-32">
                        <div className="panel-section panel-white bg-theme-up w-100">
                            <div
                                className={`panel-content   ${(indexPanel == "2000") ? "show-panel-info" : ""}`}>
                                <div className="panel-content-top px-5"

                                    onClick={(e) => setIndexPanel(indexPanel == "2000" ? "000" : "2000")}

                                >
                                    {/*<h1 className="text-20 text-super-gray font-bold">{trans("offers:offer")}</h1>*/}
                                    <h1 className="text-20 text-super-gray font-bold">{trans("offers:terms")}</h1>
                                    <img src="/assets/icons/arrow.svg"
                                        alt={"arrow"}
                                        loading={"lazy"}
                                        onError={(e) => e.target.style.display = "none"}
                                    />
                                </div>

                                <div className="panel-content-body">
                                    <div className="mx-10 my-10">
                                        {
                                            <div
                                                className={"new-lines offer-detail-data"}>

                                                <ul>
                                                    {
                                                        terms?.replaceAll("\\n\\n", "\\n")?.split('\n')?.map((note, index) => {

                                                            return (

                                                                note ?
                                                                    <li key={index} className="text-20"  >
                                                                        {/* <span className="text-16 text-gray" >{note}</span> */}
                                                                        <span className="text-20 " dangerouslySetInnerHTML={{ __html: note }} />
                                                                    </li>
                                                                    : null

                                                            );
                                                        })
                                                    }
                                                </ul>

                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )

            }
            {
                /*منتجات مماثلة*/
                (PWR && PWR.length > 0) && (
                    <div className="container mb-32">
                        <PageHeading
                            title={trans("eparts:similar-products")}
                        />

                        <Swiper
                            spaceBetween={1}
                            slidesPerView={2}
                            breakpoints={{
                                876: {
                                    slidesPerView: 6
                                },
                                576: {
                                    slidesPerView: 4,
                                },
                            }}
                            pagination={{ clickable: true }}
                            className={"mb-16k"}
                            resizeObserver={"true"}
                            autoplay={false}
                            loop={false}
                            observer={true}
                        >
                            {
                                PWR?.map((catValue, catIndex) => (
                                    <SwiperSlide className={"px-3"} key={catIndex}>
                                        {
                                            // <ServiceBox
                                            //     titlebtn={trans("eparts:titlebtn_ServiceBox")}
                                            //     link={"/eparts/group/" + proValue?.GUID}
                                            //     imgUrl={proValue.ImageUrl}
                                            //     titleEn={proValue?.DescriptionEn}
                                            //     titleAr={proValue?.DescriptionAr}
                                            // />


                                            <Part Engine_Groups={catValue?.CategiorAr}
                                                SalesPriceBefor={catValue?.SalesPriceDiscount}
                                                GUID={catValue?.GUID}
                                                favorites={favoriteProducts}
                                                Weight={catValue?.Weight}
                                                height={catValue?.height}
                                                width={catValue?.width}
                                                length={catValue?.length}
                                                ProductImage={catValue?.ProductImage}
                                                DescriptionEn={catValue?.DescriptionEn}
                                                DescriptionAr={catValue?.DescriptionAr}
                                                NetSalePrice={catValue?.SalesPrice}
                                                ProductID={catValue?.ProductID}
                                                ProductNo={catValue?.ProductNo} />
                                        }
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                        {/*<div className=" grid-4-cols">*/
                        }
                        {/*    {PWR.map(P => (*/
                        }
                        {/*        <SwiperSlide className={"px-3"} key={P.ProductID}>*/
                        }

                        {/*            <Part Engine_Groups={P.CategiorAr}*/
                        }
                        {/*                  SalesPriceBefor={P.SalesPrice}*/
                        }
                        {/*                  GUID={P.GUID}*/
                        }
                        {/*                  Weight={P.Weight} height={P.height} width={P.width} length={P.length}*/
                        }
                        {/*                  ProductImage={P.ProductImage}*/
                        }
                        {/*                  DescriptionEn={P.DescriptionEn}*/
                        }
                        {/*                  DescriptionAr={P.DescriptionAr}*/
                        }
                        {/*                  NetSalePrice={P.SalesPriceDiscount}*/
                        }
                        {/*                  ProductID={P.ProductID} ProductNo={P.ProductNo}*/
                        }
                        {/*            />*/
                        }
                        {/*        </SwiperSlide>*/
                        }
                        {/*    ))}*/
                        }
                        {/*</div>*/
                        }
                    </div>
                )
            }
        </Fragment>
    )
        ;
}