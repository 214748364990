import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import i18next from "i18next";
import { ContentLoading } from './components/contentLoading';
import CookieConsent, { Cookies } from "react-cookie-consent";
ReactDOM.render(
    <BrowserRouter basename={"/" + getLanguage()}>
        <Suspense fallback={<ContentLoading />}>
            <React.StrictMode>
                <CookieConsent
                    location="bottom"
                    enableDeclineButton
                    buttonText="قبول"
                    declineButtonText="رفض"
                    containerClasses="cookie-consent-container"
                    buttonClasses="btn btn-primary text-24  py-5"
                    declineButtonClasses="btn btn-danger text-24  py-5"
                    contentClasses="cookie-content"
                    buttonWrapperClasses='flex nice-flex-row-reverse'
                    cookieName="myAppCookieConsent"
                    // acceptOnScroll
                    // acceptOnScrollPercentage={50}
                    style={{ background: "#000000", color: "#FFFFFF", minHeight: "15rem" }}
                    buttonStyle={{ background: "#3bd461", color: "#000000", fontSize: "14px", borderRadius: "1rem" }}
                    declineButtonStyle={{ fontSize: "14px", borderRadius: "1rem" }}
                    expires={350}
                // overlay
                >
                    <p className=''> 
                        <span className='text-16 text-white'> بالنقر على "قبول"، فإنك توافق على استخدام ملفات تعريف الارتباط الخاصة بموقعنا على الويب وفقًا لما هو موضح في </span>
                        <a className='text-16 text-primary' target="_blank" href='https://hassanjameel.com.sa/ar/websitePolicy'>سياسة ملفات تعريف الارتباط الخاصة بنا</a>
                        <span className='text-16 text-white'>   . بخلاف ذلك، يمكنك الاعتراض على هذا الاستخدام بالنقر على "رفض".كما يمكنك تغيير إعدادات ملفات تعريف الارتباط الخاصة بك في أي وقت . </span>
                    </p>
                </CookieConsent>
                <App />
            </React.StrictMode>
        </Suspense>
    </BrowserRouter>
    ,
    document.getElementById('root')
)
    ;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// updateFontSize();
//
// function updateFontSize() {
//     let btns = document.querySelectorAll('.font-size-js');
//     btns.forEach((btn) => {
//         let width = btn.offsetWidth;
//         let textWidth = btn.querySelector('span').clientWidth;
//         let fontSize = ((width - textWidth) / 3) > 12 ? 12 : ((width - textWidth) / 3);
//         btn.querySelector('span').style.fontSize = fontSize + 'px';
//         console.log({width: width, textWidth: textWidth, total: ((width - textWidth) / 3)})
//     })
// }

function getLanguage() {
    return i18next.language ||
        (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
        'en';
}
;


