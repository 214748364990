import { useTranslation } from "react-i18next";
import config from "../../configuration/config.json";
export default function VehicleDetails({
  carBrand = '',
  carNameTitle = '',
  carModelTitle = '',
  isHybrid = false,
  colorImage,
  structureNo = '',
  plateNo = '',
  createdInput = '',
  values = [],
  showValues = false,
  carImage,
  userCarName = '',
  industryYear = ''
}) {
  const { t: trans } = useTranslation(["common", "vehicales", "form", "info"]);
  const preIconPath = '/assets/icons/car/';
  const lang = localStorage.getItem("i18nextLng");
  const languageDir = 'language-' + lang;
  const carInnerDetails = [
    { id: 1, description: trans("vehicales:veh-cle"), icon: preIconPath + 'cylinders.svg' },
    { id: 2, description: trans("vehicales:veh-seat"), icon: preIconPath + 'seat.svg' },
    { id: 3, description: trans("vehicales:veh-eng"), icon: preIconPath + 'engine.svg' },
    { id: 4, description: trans("vehicales:veh-lettr"), icon: preIconPath + 'gasoline.svg' },
    { id: 5, description: trans("vehicales:veh-petrol"), icon: preIconPath + 'petrol.svg' },
  ];
  return (
    <div className={'nice-container-fluid ' + languageDir}>
      <div className='nice-row'>
        <div className='nice-col-md-6 flex justify-center items-center'>

          <img className='vehicle-image-box' src={config.mainURL + carImage} alt={'info'} />
        </div>
        <div className='nice-col-md-6'>
          <div className='vehicle-details-box p-6 mt-5'>
            <div className='vehicle-details-box-brands my-3'>
              <div className='vehicle-details-box-brands-title'>{carNameTitle}</div>
              <div>
                <div className='nice-flex-row'>
                  {
                    isHybrid && <div className='vehicle-brand vehicle-brand-hybrid p-2 mx-3 text-center text-size-14r'>{trans("vehicales:veh-hybrid")}</div>
                  }
                  <div className='vehicle-brand vehicle-brand-primary p-2  text-center text-size-14r'>{carBrand}</div>
                </div>
              </div>
            </div>
            <div className='text-sub-color text-size-16r'>
              {carModelTitle}
            </div>
            <div className='text-sub-color text-size-16r my-3'>
              {industryYear}
            </div>
            <div className='nice-flex-row my-12'>
              <div className='text-16 text-sub-color'>
                {trans("form:color")} :
              </div>
              <div className='color-box' style={{ backgroundImage: `url('${(config.mainURL + colorImage).replaceAll("\\","/")}')`}}>
              </div>
            </div>
            <div className='nice-row'>
              <div className='nice-col-6'>
                <div className='nice-flex-row my-8'>
                  <div className='text-16 text-sub-color'>
                    {trans("vehicales:veh-struc")} : {structureNo}
                  </div>
                </div>
              </div>
              <div className='nice-col-6'>
                <div className='nice-flex-row my-8'>
                  <div className='text-16 text-sub-color'>
                    {trans("vehicales:veh-plat")} : {plateNo}
                  </div>
                </div>
              </div>
            </div>
            <div className='nice-row'>
              <div className='nice-col-6'>
                <div className='nice-flex-row my-8'>
                  <div className='text-16 text-sub-color'>
                    {trans("vehicales:veh-created")} : {createdInput}
                  </div>
                </div>
              </div>
              {/* <div className='nice-col-6'>
                <div className='nice-flex-row my-8'>
                  <div className='text-16 text-sub-color'>
                    {trans("info:private-name")} : {userCarName}
                  </div>
                </div>
              </div> */}
            </div>
            <div className='nice-row nice-flex-center-items nice-show'>
              {
                showValues && carInnerDetails.map((e, i) => {
                  return <div className='nice-col-lg-2 nice-col-md-3 nice-col-sm-4' key={e.id}>
                    <div className='feature-car-box'>
                      <div className='feature-car-box-details text-center'>
                        <span className='text-block text-size-14 '>
                          {
                            values && values[i]
                          }
                        </span>
                        <span className='text-block text-size-14'>
                          {e.description}
                        </span>
                      </div>
                      <img src={`${e.icon}`} className='filter-black' />
                    </div>
                  </div>
                })
              }

            </div>
          </div>

        </div>
      </div>
    </div>
  );
}