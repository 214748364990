import react, { Fragment, useState, useEffect } from "react";
import Banner from "../../components/banner";
import { Campaign } from "./components/campaign";
import { Part } from "./components/part";
import PageHeading from "../../components/__simple/_pageheading";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import { ServiceBox } from "../../components/serviceBox";
import { TestimonialSection } from "../../sections/testimonialSection";
import { WhyHJSection } from "../../sections/whyHJSection";
import PartSearch from "./components/partSearch";
import { SimpleBanner } from "../../components/__simple/_simpleBanner";
import { AboutHJSection } from "../../sections/aboutHJ";
import Breadcrumb from "../../components/__simple/breadcrumb";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Popup from "../../User/popup";
import PopupHeader from "../../User/components/popupHeader";
import PhoneDropDown from "../../components/_input/_phoneDropDown";
import { FormButton } from "../../components/_input/_button";
import Joi from "joi";
import { GetMessages, formValidate } from '../../utils/validation';
import config from '../../configuration/config.json';
import { useHistory } from 'react-router';
import CustomPopover from "./components/customPopover";
import { getData, User_Data_Key } from "../../utils/localStorageManager";
import { InputField } from "../../components/_input/_input";
import { Link } from "react-router-dom";
import React from "react";
import LandengSlide from "../landengPage/sections/landengSlide";
import SectionSlideEpart from "./components/sectionSlideEpart";

SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export function EpartsPage() {
    const GetUrl = config.baseURL + 'details';
    const GetUrlfunc = config.baseURL + 'func';
    const [PWD, setPWD] = useState([]);
    const [EngineGroups, setEngineGroups] = useState([]);
    const [isPopUpOpened, setIsPopUpOpened] = useState(false);
    const [selectedCompaign, setSelectedCompagin] = useState(0);
    const [lang, setlang] = useState(localStorage.getItem('i18nextLng'));
    const { t: trans } = useTranslation(["eparts", "common", "form", "info"]);
    const languageDir = 'language-' + localStorage.getItem('i18nextLng')
    //start_abbas_alburaee
    const history = useHistory();
    const schema = Joi.object({
        userPhoneNumber: Joi.string().min(12).required().messages(GetMessages()),
    });

    const [error, setError] = useState({});

    async function handleCompaignRequest() {
        try {
            const result = formValidate(schema, userForm) ?? {};
            setError(result);
            console.log(result);
            if (Object.keys(result).length === 0) {

                const response = await axios.post(
                    config.baseURL + 'details',
                    {
                        "object": "Web_Users",
                        "ObjectSettings": {
                            "MetaData": false
                        },
                        "Option": "column",
                        "Fields": "GUID,Web_UserID,FirstNameEn,FirstNameAr,LastNameEn,LastNameAr,Phone,Email,IdentityNumber",
                        "Filters": `where phone = '${userForm.userPhoneNumber}'`
                    }
                );

                if (response.status === 200) {
                    let urlParms = `?compaign=${selectedCompaign}`;

                    if (response.data.ApiObjectData.length > 0) {

                        const {
                            GUID,
                            Web_UserID,
                            FirstNameEn,
                            FirstNameAr,
                            LastNameEn,
                            LastNameAr,
                            Phone,
                            Email,
                            IdentityNumber
                        } = response.data.ApiObjectData[0];

                        urlParms += `&ur_gid=${GUID + '_' + String(Web_UserID)}&ur_id=${Web_UserID}&ur_en=${FirstNameEn + ' ' + LastNameEn} 
                     &ur_ar=${FirstNameAr + ' ' + LastNameAr}&ur_phone=${Phone}&ur_email=${Email}&ur_iden=${IdentityNumber} 
                     `;
                    }
                    history.push({
                        pathname: '/eparts/campaignorder',
                        search: urlParms
                    });
                    setUserForm({});
                    setSelectedCompagin(0);
                    setError({});
                }
            }

        } catch (err) {
            console.log('Error in eparts compaign', err)
        }
    }

    const [CompaignLuxes, setCompaignLuxes] = useState([]);
    const [CompaignToYota, setCompaignToYota] = useState([]);

    const [queryProduct, setQueryProduct] = useState("");

    async function RequsetCompaign() {
        const BodyToYota = {
            "object": "Web_compaing",
            "ObjectSettings": {
                "MetaData": false
            },
            "Filters": "where BrandID=1 "

        };
        const GetToYota = axios.request({
            url: GetUrl,
            data: BodyToYota, method: "POST", responseType: "json"
        })
        const BodyLuxes = {
            "object": "Web_compaing",
            "ObjectSettings": {
                "MetaData": false
            }, "Filters": "where BrandID=2"

        };
        const GetLuxes = axios.request({
            url: GetUrl,
            data: BodyLuxes, method: "POST", responseType: "json"
        })

        await axios.all([GetLuxes, GetToYota]).then(
            axios.spread(function (Luxes, ToYota) {

                setCompaignLuxes(Luxes.data.ApiObjectData);
                setCompaignToYota(ToYota.data.ApiObjectData);
                console.log("Luxes", Luxes.data.ApiObjectData);
                console.log("ToYota", ToYota.data.ApiObjectData);

            })).catch(function (error) {
                console.log(error);
            });
    }

    //End_Compaign
    function RequsetProductsWithDetails() {
        const Body = {
            "name": "Site_FeatchSparePartMain"
        };
        axios.request({
            url: GetUrlfunc,
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // console.log(response.data.ApiObjectData);
                setTimeout(() => {
                    setPWD(response?.data)
                }, 1000);

            })
            .catch(function (error) {
                console.error(error);
            });


    }

    async function RequsetsetSite_categorySP() {


        try {

            if ("caches" in window) {
                let url = "Site_categorySP";

                // Opening that particular cache
                const cacheStorage = await caches.open("HJWeb");
                // Fetching that particular cache data
                const cachedResponse = await cacheStorage.match(
                    url
                );
                let data = await cachedResponse.json();
                setTimeout(() => {
                    setEngineGroups(data)
                }, 1000);
            }

        } catch (error) {
        }


        const BodyEngineGroups = {
            "object": "Site_categorySP",
            "ObjectSettings": {
                "MetaData": false
            }
        };
        axios.request({
            url: GetUrl,
            data: BodyEngineGroups, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // console.log(response.data.ApiObjectData);
                setTimeout(async () => {
                    setEngineGroups(response.data.ApiObjectData);
                    await site_SupCategory();
                }, 10);

                const data = new Response(JSON.stringify(response.data.ApiObjectData));

                if ("caches" in window) {
                    // Opening given cache and putting our data into it
                    caches.open("HJWeb").then((cache) => {
                        cache.put("Site_categorySP", data);

                    });
                }

            })
            .catch(function (error) {
                console.log(error);
            });


    }

    const [engineGroupsSup, setEngineGroupsSup] = useState([]);
    const [engineGroupsSup2, setEngineGroupsSup2] = useState([]);
    const site_SupCategory = async () => {
        try {
            const BodyEngineGroupsSUP = {
                "object": "Site_SupCategory",
                "ObjectSettings": {
                    "MetaData": false
                }
            };

            const requestEngineGroupsSUP = axios.request({
                url: GetUrl,
                data: BodyEngineGroupsSUP, method: "POST", responseType: "json"
            });

            const BodyEngineGroupsSUP2 = {
                "object": "Site_ItemsSupCategory",
                "ObjectSettings": {
                    "MetaData": false
                }
            };

            const requestEngineGroupsSUP2 = axios.request({
                url: GetUrl,
                data: BodyEngineGroupsSUP2, method: "POST", responseType: "json"
            });

            await axios.all([requestEngineGroupsSUP, requestEngineGroupsSUP2])
                .then(
                    axios.spread(
                        (EngineGroupsSup, EngineGroupsSup2) => {
                            setTimeout(() => {
                                setEngineGroupsSup(EngineGroupsSup?.data?.ApiObjectData)
                            }, 500);
                            setTimeout(() => {
                                setEngineGroupsSup2(EngineGroupsSup2?.data?.ApiObjectData)
                            }, 700);
                        }
                    )
                ).catch((err) => {
                    throw err;
                })
        } catch (e) {
            console.log(e);
        }
    };
    // ------------------
    const [banner, setbanner] = useState([]);

    function RequsetBanner() {

        axios.request({
            baseURL: config.controllURL,
            url: lang + "/api/Maintenance/BannerSP",
            method: "get",
        })

            .then(function (response) {

                console.log("response >>>>>>>>>>>>>>>>>>>>>>>>>:", response.data.banner);
                setTimeout(setbanner(response.data.banner), 1000);

            })
            .catch(function (error) {
                console.log(error);
            });


    }

    const [Sbanner, setSbanner] = useState([]);

    function RequsetSBanner() {
        const Body = {
            "object": "siteSimpleBanner_eperts",
            "ObjectSettings": {
                "MetaData": false
            }
        };


        axios.request({
            url: GetUrl,
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                setTimeout(setSbanner(response.data.ApiObjectData), 1000);

            })
            .catch(function (error) {
                console.log(error);
            });


    }

    const [userForm, setUserForm] = useState({
        userPhoneNumber: ''
    });


    function handlePopupStatus(status, id) {
        setIsPopUpOpened(status);
        setSelectedCompagin(id);
        setError({});
    };

    const [featuredProductsList, setFeaturedProductsList] = useState([]);
    const [featuredCatList, setFeaturedCatList] = useState([]);

    const funFeaturedProductsAndCat = async () => {
        try {
            const BodyFeaturedProduct = {
                "name": "funFeaturedProducts",
                "ObjectSettings": {
                    "MetaData": false
                }
            };

            const requestFeaturedProduct = axios.request({
                url: config.baseURL + 'func',
                data: BodyFeaturedProduct, method: "POST", responseType: "json"
            })


            const BodyFeaturedCat = {
                "name": "Site_FeatchPartSparePartSpecial",
                "ObjectSettings": {
                    "MetaData": false
                }
            };

            const requestFeaturedCat = axios.request({
                url: config.baseURL + 'func',
                data: BodyFeaturedCat, method: "POST", responseType: "json"
            })


            await axios.all([requestFeaturedProduct, requestFeaturedCat])
                .then(
                    axios.spread(
                        (responseFeaturedProduct, responseFeaturedCat) => {
                            console.log("responseFeaturedProduct: ", responseFeaturedProduct.data);
                            // setTimeout(() => {
                            setFeaturedProductsList(responseFeaturedProduct?.data);
                            setFeaturedCatList(responseFeaturedCat.data);
                            // }, 500);
                        }
                    )
                ).catch((err) => {
                    throw err;
                });
        } catch (e) {
            console.log(e);
        }
    };

    const [favoriteProducts, setFavoriteProducts] = useState([]);
    // to fetch the user favorites
    const fetchUserFavorites = async () => {
        let _favorites = [];
        try {
            const isLogin = getData('isAuthenticated');
            if (isLogin) {
                const { id } = JSON.parse(getData(User_Data_Key, true));
                const userID = Number(id.toString().split('_')[1]);
                const response = await axios.post(
                    config.baseURL + 'func',
                    {
                        "Name": "SiteFetchUserFavorites",
                        "values": {
                            "userID": userID
                        }
                    }
                );
                if (response.status == 200) {
                    const favorites = response.data;
                    _favorites = _favorites.concat(favorites);
                }
            }
        } catch (err) {
            console.log(err);
        }
        return _favorites;
    };
    useEffect(() => {
        const results = fetchUserFavorites();
        setFavoriteProducts(results);
    }, []);

    useEffect(() => {
        RequsetBanner();
        RequsetCompaign();
        RequsetProductsWithDetails();
        RequsetsetSite_categorySP();
        RequsetSBanner();

        funFeaturedProductsAndCat();
    }, []);

    return (
        <Fragment>
            {
                // <Banner
                //     subtitle={banner?.subtitle}
                //     title={banner?.title}
                //     desc={banner?.excerpt}
                //     background={banner?.imagePath}
                //     backOpasity={banner?.backOpasity}
                // />
            }


            <div className="container pt-5 pb-5">
                {
                    //     <Breadcrumb className={"mt-16 "}
                    //              links={[
                    //                  {
                    //                      name: trans("eparts:EpartTitel")
                    //                  }
                    //              ]}
                    // />
                }

                {
                    <div
                        className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-8 lg:grid-cols-8 xl:grid-cols-8 gap-1
                         justify-items-auto ">
                        <div className="col-span-1 flex gap-3 order-2-sm ">
                            {/* {
                                <Breadcrumb className={"mt-16 "}
                                            links={[
                                                {
                                                    name: trans("eparts:EpartTitel")
                                                }
                                            ]}
                                />
                            } */}
                            <div className="col-span-5 flex justify-center items-center">
                                <Link

                                    to={"/eparts/sereach?" + "allCategories=true"}
                                    className="text-4xl sm:text-3xl md:text-4xl lg:text-3xl xl:text-3xl hover:text-green-500  "
                                >
                                    {lang.toLowerCase() == "ar" ? "جميع الفئات" : "Categories"}
                                </Link>
                            </div>
                            {/* <div className="col-span-5 flex justify-center items-center">
                                <Link

                                    to={"/eparts/sereach?" + "allCategories=true"}
                                    className="text-4xl sm:text-3xl md:text-4xl lg:text-3xl xl:text-3xl hover:text-green-500  "
                                >
                                    {lang.toLowerCase() == "ar" ? " العروض" : " Categories"}
                                </Link>
                            </div> */}
                        </div>

                        {/*<div>2</div>*/}
                        <div className="flex justify-center items-center px-3 col-span-5">
                            <div
                                className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-0 justify-items-auto w-full ">
                                <div className=" sm:col-span-1 md:col-span-3 lg:col-span-3 xl:col-span-3">
                                    {
                                        <div className={"flex justify-center items-center "}>
                                            <div className="filter-search-grid filter-search-1 h-90">

                                                <InputField
                                                    searchIcon={true}
                                                    extraClasses="part-input-search text-20 "
                                                    // placeholder={trans("new:product_no")}
                                                    placeholder={trans("info:name-number-search")}
                                                    onChangeValue={(e) => {
                                                        setQueryProduct(null);
                                                        // SetProduct(e);
                                                        console.warn(e.target.value);
                                                        setQueryProduct(e.target.value);
                                                    }
                                                    }
                                                    onClicksearchIcon={(value) => {

                                                        if (queryProduct)
                                                            history.push("/eparts/sereach?Product=" + queryProduct);
                                                    }}
                                                    value={queryProduct}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                                {/* <div
                                    className=" sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 flex justify-center items-center mt-8 sm:mt-8 md:mt-0">
                                    {
                                        queryProduct && (
                                            <Link
                                                onClick={() => {
                                                    // refreshPage("ProductID")
                                                }}
                                                to={"/eparts/sereach?Product=" + queryProduct}
                                                className={"btn btn-primary text-16 w-full sm:w-full md:w-80 h-[2rem] sm:mb-10 md:h-20 lg:h-16"}
                                            >
                                                {trans("eparts:search")}
                                            </Link>

                                        )
                                    }
                                </div> */}

                            </div>
                        </div>
                    </div>
                }
            </div>
            {
                // EngineGroups.length != 0 && engineGroupsSup.length!= 0 && engineGroupsSup2.length!=0 && (
                //     <div className="container">
                //         <CustomPopover listMain={EngineGroups} level2={engineGroupsSup} level3={engineGroupsSup2}/>
                //     </div>
                // )
            }

            <PartSearch isIndex={true} />


            {
                <SectionSlideEpart />
            }

            <div className={"container my-32 " + languageDir}>

                {
                    CompaignToYota?.length > 0 &&
                    <>
                        <PageHeading title={trans("eparts:EpartsTitel")} />


                        <div className={"mb-32 "}>
                            <div className={"flex gap-2 items-center mb-16 "}>
                                <img className={'h-16'} src="/assets/brands/toyota.svg" alt="toyota" />
                                <h2 className={"text-20 font-bold"}> {trans("eparts:ToytaTitel")}</h2>
                            </div>
                            <Swiper
                                slidesPerView={2}
                                spaceBetween={5}
                                resizeObserver={"true"}
                                watchSlidesVisibility
                                watchSlidesProgress
                                autoplay={false}
                                loop={false}
                            >


                                {CompaignToYota?.map(ToYota => {
                                    if (lang === "en") {

                                        return (
                                            <SwiperSlide>
                                                <div className="nice-flex ">
                                                    <Campaign TotalAmountDiscount={ToYota.TotalAmountDiscount}
                                                        TotalTime={ToYota.TotalTime}
                                                        startDate={new Date(ToYota.DateFrom)}
                                                        endDate={new Date(ToYota.DateTo)}
                                                        CampaignType={ToYota.CampaignType} Photo={ToYota.Photo}
                                                        GUID={ToYota.GUID} DiscountID={ToYota.DiscountID}
                                                        BrandID={ToYota.BrandID}
                                                        DiscountAmount={ToYota.DiscountAmount} Type={ToYota.Type}
                                                        DateTo={ToYota.DateTo} DateFrom={ToYota.DateFrom}
                                                        Description={ToYota.DescriptionEn}
                                                        Title={ToYota.DescriptionEnTitle}
                                                        to='/eparts/compaigndetails' toyota={true}
                                                        onChooseCompaign={(status, id) => {
                                                            handlePopupStatus(true, id)
                                                        }} />
                                                </div>
                                            </SwiperSlide>
                                        );
                                    } else {
                                        return (
                                            <SwiperSlide>
                                                <div className="nice-flex ">
                                                    <Campaign TotalAmountDiscount={ToYota.TotalAmountDiscount}
                                                        TotalTime={ToYota.TotalTime}
                                                        startDate={new Date(ToYota.DateFrom)}
                                                        endDate={new Date(ToYota.DateTo)}
                                                        CampaignType={ToYota.CampaignType} Photo={ToYota.Photo}
                                                        GUID={ToYota.GUID} DiscountID={ToYota.DiscountID}
                                                        BrandID={ToYota.BrandID}
                                                        DiscountAmount={ToYota.DiscountAmount} Type={ToYota.Type}
                                                        DateTo={ToYota.DateTo} DateFrom={ToYota.DateFrom}
                                                        Description={ToYota.DescriptionAr}
                                                        Title={ToYota.DescriptionArTitle}
                                                        to='/eparts/compaigndetails' toyota={true}
                                                        onChooseCompaign={(status, id) => {
                                                            handlePopupStatus(true, id)
                                                        }} />
                                                </div>
                                            </SwiperSlide>
                                        );
                                    }
                                })}
                            </Swiper>
                        </div>

                    </>
                }

                {

                    CompaignLuxes?.length > 0 &&
                    <div className={"mb-32 " + languageDir}>

                        <div className={"flex gap-2 items-center mb-16"}>
                            <img className={'h-16'} src="/assets/brands/lexus.svg" alt="toyota" />
                            <h2 className={"text-20 font-bold"}>{trans("eparts:lxcTitel")}</h2>
                        </div>
                        <Swiper
                            slidesPerView={2}
                            spaceBetween={5}
                            resizeObserver={"true"}
                            watchSlidesVisibility
                            watchSlidesProgress
                            autoplay={false}
                            loop={false}

                        >

                            {CompaignLuxes?.map(Luxes => {
                                if (lang === "en") {

                                    return (
                                        <SwiperSlide className="mx-8">
                                            <div className="nice-flex">
                                                <Campaign TotalAmountDiscount={Luxes.TotalAmountDiscount}
                                                    TotalTime={Luxes.TotalTime}
                                                    startDate={new Date(Luxes.DateFrom)}
                                                    endDate={new Date(Luxes.DateTo)}
                                                    CampaignType={Luxes.CampaignType} Photo={Luxes.Photo}
                                                    GUID={Luxes.GUID} DiscountID={Luxes.DiscountID}
                                                    BrandID={Luxes.BrandID} DiscountAmount={Luxes.DiscountAmount}
                                                    Type={Luxes.Type} DateTo={Luxes.DateTo}
                                                    DateFrom={Luxes.DateFrom} Description={Luxes.DescriptionEn}
                                                    Title={Luxes.DescriptionEnTitle} to='/eparts/compaigndetails'
                                                    toyota={false} onChooseCompaign={(status, id) => {
                                                        handlePopupStatus(true, id)
                                                    }} />
                                            </div>
                                        </SwiperSlide>
                                    );
                                } else {
                                    return (


                                        <SwiperSlide className="mx-8">
                                            <div className="nice-flex ">
                                                <Campaign TotalAmountDiscount={Luxes.TotalAmountDiscount}
                                                    TotalTime={Luxes.TotalTime}
                                                    startDate={new Date(Luxes.DateFrom)}
                                                    endDate={new Date(Luxes.DateTo)}
                                                    CampaignType={Luxes.CampaignType} Photo={Luxes.Photo}
                                                    GUID={Luxes.GUID} DiscountID={Luxes.DiscountID}
                                                    BrandID={Luxes.BrandID} DiscountAmount={Luxes.DiscountAmount}
                                                    Type={Luxes.Type} DateTo={Luxes.DateTo}
                                                    DateFrom={Luxes.DateFrom} Description={Luxes.DescriptionAr}
                                                    Title={Luxes.DescriptionArTitle} to='/eparts/compaigndetails'
                                                    toyota={false} onChooseCompaign={(status, id) => {
                                                        handlePopupStatus(true, id)
                                                    }} />
                                            </div>
                                        </SwiperSlide>

                                    );
                                }
                            })}

                        </Swiper>
                    </div>
                }

            </div>

            {
                // الفئات المميزة
                featuredProductsList.length > 0 && (
                    <div className={"container mb-40 " + languageDir}>
                        <PageHeading
                            title={trans("eparts:featured-products")}
                        />
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={2}
                            breakpoints={{
                                576: {
                                    slidesPerView: 4,
                                },
                            }}
                            pagination={{ clickable: true }}
                            className={"mb-16k"}
                            resizeObserver={"true"}
                            autoplay={false}
                            loop={false}
                            observer={true}
                        >

                            {featuredProductsList?.map((proValue, prodIndex) => (
                                <SwiperSlide className={"px-3"} key={proValue.GroupIDMain}>
                                    <ServiceBox
                                        titlebtn={trans("eparts:titlebtn_ServiceBox")}
                                        // link={"/eparts/group/" + proValue.GUID} 
                                        link={`eparts/sereach?CatMain=${proValue?.GroupIDMain}&SuPCat=null&SSuPCat=null`}
                                        imgUrl={proValue.ImageUrl}
                                        titleEn={proValue.DescriptionEn}
                                        titleAr={proValue.DescriptionAr} />
                                </SwiperSlide>
                            ))}

                        </Swiper>

                    </div>
                )
            }

            {
                //     القطع المميزة
                featuredCatList.length > 0 && (
                    <div className={"container mb-40 " + languageDir}>
                        <PageHeading
                            title={trans("eparts:distinctive-pieces")}
                        />
                        <Swiper
                            spaceBetween={1}
                            slidesPerView={2}
                            breakpoints={{
                                576: {
                                    slidesPerView: 6,
                                },
                            }}
                            pagination={{ clickable: true }}
                            className={"mb-16k"}
                            resizeObserver={"true"}
                            autoplay={false}
                            loop={false}
                            observer={true}
                        >

                            {featuredCatList?.map((catValue, catIndex) => (
                                <SwiperSlide className={"px-3"} key={catIndex}>
                                    {
                                        catValue.SalesPriceDiscount > 0
                                            ? <Part Engine_Groups={catValue.CategiorAr}
                                                SalesPriceBefor={catValue.SalesPrice}
                                                GUID={catValue.GUID}
                                                Weight={catValue.Weight} height={catValue.height}
                                                width={catValue.width} length={catValue.length}
                                                ProductImage={catValue.ProductImage}
                                                DescriptionEn={catValue.DescriptionEn}
                                                DescriptionAr={catValue.DescriptionAr}
                                                NetSalePrice={catValue.SalesPriceDiscount}
                                                ProductID={catValue.ProductID}
                                                ProductNo={catValue.ProductNo} />

                                            : <Part Engine_Groups={catValue.CategiorAr}
                                                SalesPriceBefor={catValue.SalesPriceDiscount}
                                                GUID={catValue.GUID}
                                                favorites={favoriteProducts}
                                                Weight={catValue.Weight} height={catValue.height}
                                                width={catValue.width}
                                                length={catValue.length}
                                                ProductImage={catValue.ProductImage}
                                                DescriptionEn={catValue.DescriptionEn}
                                                DescriptionAr={catValue.DescriptionAr}
                                                NetSalePrice={catValue.SalesPrice}
                                                ProductID={catValue.ProductID}
                                                ProductNo={catValue.ProductNo} />

                                    }
                                </SwiperSlide>
                            ))}

                        </Swiper>

                    </div>
                )
            }


            <WhyHJSection />

            <div className={"container mb-40 " + languageDir}>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={2}
                    breakpoints={{
                        576: {
                            slidesPerView: 4,
                        },
                    }}
                    pagination={{ clickable: true }}
                    className={"mb-16k"}
                    resizeObserver={"true"}
                    autoplay={false}
                    loop={false}
                    observer={true}
                >

                    {EngineGroups?.map(H => (
                        <SwiperSlide className={"px-3"} key={H.GroupIDMain}>
                            <ServiceBox
                                titlebtn={trans("eparts:titlebtn_ServiceBox")}
                                // link={"/eparts/group/" + H.GUID} GroupIDMain
                                link={`eparts/sereach?CatMain=${H?.GroupIDMain}&SuPCat=null&SSuPCat=null`}
                                img={H.image}
                                titleEn={H.DescriptionEn}
                                titleAr={H.DescriptionAr} />
                        </SwiperSlide>
                    ))}

                </Swiper>

            </div>

            <div className={"container mb-32 hide-xs " + languageDir}>

                {Sbanner?.map(b => {
                    if (lang === "en") {
                        return (<SimpleBanner icon={"data:image/png;base64," + b.icon}
                            banner={"data:image/png;base64," + b.imageEn} title={b.TitleEN}
                            subtitle={b.DescriptionEn} />);
                    } else {
                        return (<SimpleBanner icon={"data:image/png;base64," + b.icon}
                            banner={"data:image/png;base64," + b.imageAr} title={b.TitleAr}
                            subtitle={b.DescriptionAr} />);
                    }
                })}

            </div>

            <div className={" container mb-36 " + languageDir}>
                {
                    PWD?.length > 0 &&
                    <>
                        <PageHeading
                            subtitle={trans("eparts:EpartTitel")}
                            title={trans("eparts:exlantProduct")}
                        />
                        <br />


                        <Swiper
                            spaceBetween={0}
                            slidesPerView={2}
                            breakpoints={{
                                576: {
                                    slidesPerView: 4,
                                },
                            }}
                            navigation
                            pagination
                            className={"mb-16k"}
                            resizeObserver={"true"}
                            autoplay={false}
                            loop={false}
                            observer={true}
                        >


                            {PWD?.map(P => (
                                <SwiperSlide className={"px-3"} key={P.ProductID}>
                                    <Part ProductNo={P.ProductNo} Engine_Groups={P.CategiorAr}
                                        Discount={P.DiscountAmount} Tax={P.Percentage} GUID={P.GUID} Weight={P.Weight}
                                        height={P.height} width={P.width} length={P.length}
                                        ProductImage={P.ProductImage} DescriptionEn={P.DescriptionEn}
                                        DescriptionAr={P.DescriptionAr} SalesPriceBefor={0}
                                        NetSalePrice={P.SalesPrice} ProductID={P.ProductID} />
                                </SwiperSlide>
                            ))}

                        </Swiper>
                        <br />

                    </>
                }
            </div>


            <AboutHJSection />

            <TestimonialSection />

            <Popup show={isPopUpOpened}>
                <div className='popup-model'>
                    <PopupHeader welcomeTitle={trans("eparts:order-now")} subTitle={trans("eparts:fill-phone")}
                        closePopUp={() => handlePopupStatus(false, 0)} />
                    <div className='popup-content'>
                        <PhoneDropDown
                            title={trans("form:phone")}
                            errorLabel={error.userPhoneNumber}
                            onChangeValue={(countryCode, phoneNumber) => {
                                const val = { ...userForm };
                                val.userPhoneNumber = `+${countryCode}${phoneNumber}`;
                                setUserForm(val);
                            }}
                        />
                        <FormButton name={trans("form:next")} type='button'
                            onClick={
                                handleCompaignRequest
                            } />
                    </div>
                </div>
            </Popup>

        </Fragment>
    );
}