import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState, useContext } from "react";
import { Price } from "../../../components/__simple/_price";
import axios from "axios";
import config from '../../../configuration/config.json';
import { union } from "lodash";
import { Link } from "react-router-dom";
import { groupBy, mapValues, omit, trimEnd } from "lodash";
import ConfirmContext from "../../../utils/confirmContext";
export function CarSpecsTile({ modelslug = null, group, type, showPric, name, price, image, buyOnline, spec, backupImage, hp, cylinders, liter, seats, petrol, offerIndex = 0, productID, colorID, productTarget, brand, hybird, newCar, modelYear }) {
    const { t: trans } = useTranslation(["common", "car", "offers", "vehicales", "model", "info"]);
    const specRef = useRef(null);
    const [specHeight, setHeight] = useState(0);
    const lang = localStorage.getItem("i18nextLng");;
    const languageDir = 'language-' + localStorage.getItem("i18nextLng");
    const context = useContext(ConfirmContext);

    function showData() {

        let vx = document.getElementsByClassName("is-show");
        
        // vx.map((ev)=> {
        //     ev.style.height= '0px'
        // });

        Array.from(vx).forEach((ev) => {
            ev.style.height = '0px';
        });

        setTimeout(()=>{
            setHeight(specHeight <= 0 ? specRef.current.scrollHeight : 0);
        }, 5);

        
    }
    const [features, setFeatures] = useState([]);
    useEffect(() => {
        formatFeatures();
    }, []);

    useEffect(() => {

        if (modelslug != null && productTarget != null) {
            if (productTarget?.toLowerCase() == modelslug?.toLowerCase() && specHeight <= 0) {
                showData();
                if (specRef?.current?.scrollHeight) {

                    window.scrollTo({
                        top: specRef?.current?.offsetTop - 200,
                        behavior: 'smooth'
                        /* you can also use 'auto' behaviour
                           in place of 'smooth' */
                    });
                }

            }
        }
    }, [modelslug, productTarget, specRef?.current?.scrollHeight]);
    // to format the features
    const formatFeatures = async () => {
        try {
            const splittedValues = spec?.split('\n');
            if (productTarget) {
                // axios.post(
                //     config.baseURL + 'func',
                //     {
                //         "name": "SiteGetCarModelProperities",
                //         "values": {
                //             "ID": `${productID}`
                //         }
                //     }
                // ).then(response => {
                //     const values = response.data.map(item => {
                //         return item[`${trans("model:description")}`]
                //     });
                //     const unionValues = union(splittedValues, values);
                //     setFeatures(unionValues);
                // }).catch(error => {
                //     setFeatures(splittedValues);
                //     console.log('Error no values were coming by request', error);
                // });


                await axios.post(
                    config.baseURL + 'func',
                    {
                        "name": "Fetch_InvProductsEquipments",
                        "values": {
                            "GUID": `${productTarget}`
                        }
                    }
                ).then(response => {

                    const groupResult = mapValues(groupBy(response.data, 'ID'), fList => fList.map(items => omit(items, 'ID')));

                    var dataFinal = [];
                    Object.keys(groupResult)?.map((key, index) => {

                        var data = [];
                        var note = "";
                        groupResult[key].forEach((a, i) => {
                            if (lang == "ar") {
                                note = note + a.DescriptionAr + "\n" + a.NoteAr + "\n";
                                a.note = note;
                            } else {
                                note = note + a.DescriptionEn + "\n" + a.NoteEn + "\n";
                                a.note = note;
                            }

                            if (i == (groupResult[key].length - 1))
                                data = [...data, a];

                        })
                        dataFinal = [...dataFinal, ...data];

                    })

                    // const unionValues = union(splittedValues, values);
                    setFeatures(dataFinal);
                }).catch(error => {
                    // setFeatures(splittedValues);
                    console.log('Error no values were coming by request', error);
                });


            }
        } catch (error) {
            console.log(`Error in spliting values ${error}`);
        }
    };
    function sendRequest() {
        try {
            context.onShow({

                displayConfirm: true,
                title: name,
                image: image,
                brand: brand,
                type: type,
                isHyper: hybird,
                isNew: newCar,
                defaultColorID: colorID,
                modelYear: modelYear,
                cartDetails: {
                    id: productID,
                    name: name,
                    brand: brand,
                    image: image,
                    Type: "car",
                },
                carOrder: {
                    vehicle: productID,
                    group: group
                },
            });
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className={"specs-tile mb-16 " + languageDir}>
            <div onClick={showData} className="specs-tile-bg">

                <div className="specs-tile-content">
                    <div className="specs-tile-img">

                        <img className={"object-full object-contain"}
                            src={image !== "null" ? config.mainURL + image : config.mainURL + backupImage}
                            alt={name} />

                        {/* <div className="specs-tile-offers">
                            {trans("offers:offer") + ' ' + offerIndex}
                        </div> */}
                    </div>
                    <div className="specs-tile-info pr-20">
                        <div className="specs-tile-info-name">
                            <h3 className={"text-24 mb-4 text-line-clamp text-line-clamp-2"}>
                                {name}
                            </h3>
                            {showPric && <div className="options-price ">
                                <span className="text-20 ml-3 ">{trans("info:product_price")}</span>
                                <h4 className={"text-20 font-bold inline-block text-primary"}>
                                    <Price price={price} />
                                </h4>
                            </div>}
                            <div>
                                <div className={"car-tags"}>
                                    {(buyOnline == true) && <span className={"bg-black tag text-white"}>{trans("info:Available_for_purchase_online")}</span>}
                            
                                </div>
                                <Link to={"#"} className="text-primary nice-flex nice-flex-row mt-8" onClick={sendRequest}>
                                    <span className="text-primary text-14">{trans("info:buy")}</span>
                                    <img src='/assets/icons/cart.svg' className="filter-primary" alt="hassan jameel carts " />
                                </Link>
                            </div>
                            </div>
                            <div className={"specs-tile-info-spec hide-xs"}>

                                <div>
                                    <span className={"text-24 flex items-center flex-col"}>
                                        <span className={"text-24"}>{cylinders}</span>
                                        <span className={"text-12"}>{trans("car:cylinders")}</span>
                                    </span>
                                    <div className="spec-img">
                                        <img className={"filter-black dark-filter-gray"} src="/assets/icons/car/cylinders.svg"
                                            alt="engine" />
                                    </div>
                                </div>

                                <div>
                                    <span className={"text-24 flex items-center flex-col"}>
                                        <span className={"text-24"}>{seats}</span>
                                        <span className={"text-12"}>{trans("car:seats")}</span>
                                    </span>
                                    <div className="spec-img">
                                        <img className={"filter-black dark-filter-gray"} src="/assets/icons/car/seat.svg"
                                            alt="engine" />
                                    </div>
                                </div>
                                <div>
                                    <span className={"text-24 flex items-center flex-col"}>
                                        <span className={"text-24"}>{hp}</span>
                                        <span className={"text-12"}>{trans("car:horse")}</span>
                                    </span>
                                    <div className="spec-img">
                                        <img className={"filter-black dark-filter-gray"} src="/assets/icons/car/engine.svg"
                                            alt="engine" />
                                    </div>
                                </div>

                                <div>
                                    <span className={"text-24 flex items-center flex-col"}>
                                        <span className={"text-24"}>{liter}</span>
                                        <span className={"text-12"}>{trans("car:liter")}</span>
                                    </span>
                                    <div className="spec-img">
                                        <img className={"filter-black dark-filter-gray"} src="/assets/icons/car/gasoline.svg"
                                            alt="engine" />
                                    </div>
                                </div>

                                <div>
                                    <span className={"text-24 flex items-center flex-col"}>
                                        <span className={"text-24"}>{petrol}</span>
                                        <span className={"text-12"}>{trans("vehicales:veh-petrol")}</span>
                                    </span>
                                    <div className="spec-img">
                                        <img className={"filter-black dark-filter-gray"} src="/assets/icons/car/petrol.svg"
                                            alt="petrol" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="specs-tile-toggle">

                    </div>
                </div>



                <div ref={specRef} style={{ height: specHeight + "px" }}
                    className={"transition overflow-hidden is-show"}>

                    {
                        features?.map((details, index) => {
                            return (
                                (details["note"] && details["note"].split('\n').length > 0 && details["note"].split('\n')[0] != "null" && trimEnd(details["note"]).toString() != "") ?
                                    <div className="m-10" key={index} >
                                        <h1 className="text-30 text-super-gray  font-bold">{lang == "ar" ? details?.sectionAr : details?.sectionEn}</h1>

                                        <ul className={"m-10 list-bullet col-count-3  m-12 text-20 list-gray"}>
                                            {
                                                details["note"].split('\n').map((note, index) => {

                                                    return (

                                                        trimEnd(note) ?
                                                            <li key={index} className=" text-20"  >
                                                                <span className="text-20" >{note}</span>
                                                            </li>
                                                            : null

                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                    : null
                            );
                        })
                    }

                </div>

                {/* <div ref={specRef} style={{ height: specHeight + "px" }}
                className={"transition overflow-hidden"}>
                <ul className={"list-bullet col-count-3  m-12 list-gray"}>
                    {
                        features?.map((item, index) => {
                            return (
                                <li key={index}>
                                    {
                                        item
                                    }
                                </li>
                            )
                        })
                    }
                </ul>
            </div> */}
            </div>
            );
}

